<template>
    <div class="chat__item" :class="{'chat__item--author': message.is_author}">
        <div class="chat__content">
            <div v-if="showDate" class="message-date">{{ messageDate }}</div>
            <header v-if="showMessageMetaInfo" class="chat__header">
                <h6 class="heading6">{{ message.member_name }}</h6>
                <time class="chat__time">{{ messageTime }}</time>
            </header>
            <div class="chat__body">
                <div v-if="showMessageMetaInfo" class="chat__author">
                    <Avatar
                        :username="message.member_name"
                        :src="message.member_avatar"
                        :size="38">
                    </Avatar>
                    <span class="chat__author__external" v-if="message.sent_by_external_user"><img :src="LogoHelper.getLogo(logo)" alt=""></span>
                </div>

                <template v-if="message.message_type == 'text'">
                    <p class="message">{{message.text}}</p>
                </template>

                <template v-else-if="message.tracker && message.message_type == 'share_tracker'">
                    <template v-if="!message.sent_by_current_company">
                        <div class="message mb-3">
                            <p>{{message.member_name}} has invited you to collaborate on opportunity. This will allow your company to add startups from your ecosystem that
                                match {{message.tracker.company_name}}'s needs. See opportunity details below:
                            </p>
                            <p>
                                <b>{{message.tracker.name}}</b>
                                <span v-html="message.tracker.description"></span>
                            </p>
                            <div class="text-center mb-2">
                                <a :href="'/opportunities/' + message.tracker.id" target="_blank" class="btn btn-primary btn-lg">Go To Opportunity</a>
                            </div>
                        </div>

                    </template>

                    <template v-else>
                        <footer class="chat__footer">
                            <div class="message mb-3">
                                <p class="mb-0">
                                    You sent {{message.tracker.ecosystem}} an invite to add startups to the tracker {{message.tracker.name}}.
                                </p>
                                <div class="text-center">
                                    <a :href="'/trackers/' + message.tracker.id" target="_blank" class="btn btn-primary btn-lg">Go To Tracker</a>
                                </div>
                            </div>
                        </footer>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ChatMessage',
        components: {
            Avatar: () => import("vue-avatar"),
        },
        props: {
            message: {type: Object, required: true},
            messages: {type: Array, required: true},
            logo: {type: String},
            index: {type: Number, required: true},
        },
        computed: {
            messageDate() {
                return this.shortDate()
            },
            messageTime() {
                return this.shortTime()
            },
            showDate() {
                return (!this.isLastMessage() && (this.shortDate() !== this.shortDate(this.messages[this.index + 1].created_at))) || this.isLastMessage()
            },
            showMessageMetaInfo() {
                return (!this.isLastMessage() && (this.shortTime() !== this.shortTime(this.messages[this.index + 1].created_at))) || this.isLastMessage()
            },
        },
        methods: {
            isLastMessage() {
                return this.message.id === this.messages.at(-1).id
            },
            shortDate(date = this.message.created_at) {
                return this.dateHelper.decodeDateTz(date, ['month-str', 'no-current-year'])
            },
            shortTime(date = this.message.created_at) {
                return this.dateHelper.decodeTimeTz(date)
            },
        }
    }
</script>
